import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Button from "@ui/button";
import { SectionTitleType, ItemType } from "@utils/types";
import {
  SectionWrap,
  JobList,
  JobItem,
  JobTitle,
  JobType,
  JobDesc,
  JobButton,
} from "./style";

const ListingArea = () => {
  const data = {
    items: [
      {
        id: "1",
        title: "Matrimonios",
        description:
          "DK Catering & Eventos es tu mejor propuesta de servicios de catering para bodas en Lima. Considerado como uno de los principales proveedores de servicios de catering para bodas en la ciudad de Lima, entendemos todo lo relacionado con las bodas. ¡No hay mejor lugar para celebrar el amor que Lima y no hay mejor socio de catering para bodas que DK Catering & Eventos!",
      },
      {
        id: "1",
        title: "Eventos Corporativos",
        description:
          "Como expertos en eventos corporativos y catering para fiestas, DK Catering & Eventos es tu opción para catering corporativo. Un elemento básico en el mercado de servicios de banquetes corporativos de Lima, ofrecemos deliciosa comida, bebidas y diseño de eventos para sus reuniones de oficina, funciones de clientes y fiestas de fin de año.",
      },
      {
        id: "1",
        title: "Fiestas Privadas",
        description:
          "DK Catering & Eventos es tu socio de catering de servicio completo para satisfacer todos los hitos de la vida. Clasificada como una de las principales empresas de catering y eventos de Lima, permítenos ayudarte a planificar tu baby shower, fiesta de cumpleaños, cena de graduación, quinceañero, celebración de la vida u ocasión especial.",
      },
    ],
  };
  return (
    <SectionWrap>
      <Container>
        {data?.section_title && (
          <SectionTitle
            mb={["30px", null, "45px"]}
            maxWidth={["700px"]}
            mx="auto"
            {...data.section_title}
          />
        )}
        <JobList>
          {data?.items?.map((job, i) => {
            const isEven = i % 2 === 0;
            return (
              <JobItem key={job.id} isEven={isEven}>
                <Row>
                  <Col lg={3}>
                    <JobTitle>{job.title}</JobTitle>
                  </Col>
                  <Col lg={6}>
                    <JobDesc>{job.description}</JobDesc>
                  </Col>
                  <Col lg={3}>
                    <JobButton>
                      <Button to="/contacto" variant="outlined" color="light">
                        Cotizar
                      </Button>
                    </JobButton>
                  </Col>
                </Row>
              </JobItem>
            );
          })}
        </JobList>
      </Container>
    </SectionWrap>
  );
};

ListingArea.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.shape(SectionTitleType),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
  }),
};

export default ListingArea;
