import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import { getSrc } from "gatsby-plugin-image";
import { SectionTitleType, ImageType, ButtonType } from "@utils/types";

import {
  SectionWrap,
  GalleryWrap,
  GalleryGrid,
  GalleryItem,
  GalleryOverlay,
  GalleryOverlayIcon,
} from "./style";

const GalleryArea = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "fotos" } }) {
        edges {
          node {
            original: childImageSharp {
              gatsbyImageData(width: 1320)
            }
            thumbnail: childImageSharp {
              gatsbyImageData(width: 800)
            }
            name
          }
        }
      }
    }
  `);

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const onClickHandler = (e, i) => {
    setIsOpen(true);
    setPhotoIndex(i);
  };

  const pictures = data.allFile.edges;

  const images = pictures.map(({ node: image }) => ({
    original: getSrc(image.original),
    thumbnail: getSrc(image.thumbnail),
    name: image.name ? image.name : "",
  }));
  console.log(images);

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <GalleryWrap>
              {images.map((image, i) => (
                <GalleryGrid key={image.name}>
                  <GalleryItem>
                    <Image
                      src={image.thumbnail}
                      alt={image.name || "gallery"}
                    />
                    <GalleryOverlay />
                    <GalleryOverlayIcon onClick={(e) => onClickHandler(e, i)}>
                      <i className="icon far fa-search"></i>
                    </GalleryOverlayIcon>
                  </GalleryItem>
                </GalleryGrid>
              ))}
              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex].original}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex + images.length - 1) % images.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images.length)
                  }
                />
              )}
            </GalleryWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

GalleryArea.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.shape(SectionTitleType),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
  }),
};

export default GalleryArea;
