import React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "@ui/wrapper";
import Anchor from "@ui/anchor";
import Button from "@ui/button";
import { HeadingType, TextType, ButtonType } from "@utils/types";
import {
  ConatactWrapper,
  ContactInfoBox,
  StyledHeading,
  StyledText,
  StyledInfoTitle,
  StyledBG,
} from "./style";

const ContactArea = ({ data }) => {
  return (
    <ConatactWrapper>
      <StyledBG>
        <StaticImage
          src="../../../assets/images/bg/contact-bg.jpg"
          alt="Contact BG"
        />
      </StyledBG>
      <Container>
        <Row alignItems="center">
          <Col lg={6}>
            <StyledHeading>Comienza a planificar hoy</StyledHeading>
            <StyledText>
              Nuestros planificadores están listos para responder a sus
              preguntas y hacer realidad su visión.
            </StyledText>
          </Col>
          <Col lg={6}>
            <ContactInfoBox>
              <i className="icon fal fa-phone"></i>
              <StyledInfoTitle>Escríbenos</StyledInfoTitle>

              <h2>
                <Anchor
                  path="/"
                  color="#002fa6"
                  hover={{ layout: 2, color: "#002fa6" }}
                >
                  +51 981 182 525
                </Anchor>
              </h2>
              <Button key="i" path="#" mt="20px" minWidth="230px">
                Contactáctanos
              </Button>
            </ContactInfoBox>
          </Col>
        </Row>
      </Container>
    </ConatactWrapper>
  );
};

ContactArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
  }),
};

export default ContactArea;
