import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@ui/page-header/layout-02";
import ListingArea from "@ui/listing/layout-01";
import GalleryArea from "@ui/gallery/layout-01";
import ContactArea from "@containers/contacto/layout-01";

const EventosPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Eventos" />
      <Header transparent />
      <main className="site-wrapper-reveal">
        <PageHeader title="Eventos" />
        <ListingArea />
        <GalleryArea />
        <ContactArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default EventosPage;
